<template>
    <div class="content">
        <template v-for="area of content">
            <PostContent 
                :key="area.id"
                v-if="area.blockType === 'postContent'"
                :content="area.content"
            ></PostContent>
            <FullWidthImage
                :key="area.id"
                v-if="area.blockType === 'fullWidthImage'"
                :content="area"
            ></FullWidthImage>
            <VideoEmbed
                :key="area.id"
                v-if="area.blockType === 'videoEmbed'"
                :content="area"
            ></VideoEmbed>
            <BlockQuote
                :key="area.id"
                v-if="area.blockType === 'blockQuote'"
                :content="area"
            ></BlockQuote>
            <AudioEmbed
                :key="area.id"
                v-if="area.blockType === 'audioEmbed'"
                :content="area"
            ></AudioEmbed>
            <SocialIcons
                :key="area.id"
                v-if="area.blockType === 'socialIcons'"
                :content="area"
            ></SocialIcons>
            <Columns
                :key="area.id"
                v-if="area.blockType === 'columns'"
                :content="area"
            ></Columns>
            <ColoredBackgroundArea
                :key="area.id"
                v-if="area.blockType === 'coloredBackgroundArea'"
                :content="area"
            ></ColoredBackgroundArea>
            
        </template>
    </div>
</template>

<script>
import PostContent from './PostContent.vue'
import FullWidthImage from './FullWidthImage.vue'
import Columns from './Columns.vue'
import VideoEmbed from './VideoEmbed.vue'
import BlockQuote from './BlockQuote.vue'
import SocialIcons from './SocialIcons.vue'
import AudioEmbed from './AudioEmbed.vue'
import ColoredBackgroundArea from './ColoredBackgroundArea.vue'
export default {
    props: ['content'],
    data() {
        return {

        }
    },
    components: {
        PostContent,
        FullWidthImage,
        Columns,
        VideoEmbed,
        BlockQuote,
        SocialIcons,
        AudioEmbed,
        ColoredBackgroundArea
    },
    
}
</script>
