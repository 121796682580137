<template>
  <div>
    <Header></Header>

    <section class="py-7">
      <div class="container">
        <div class="row gy-7">
          <div class="col-12 col-lg-8 col-xxl-9">
            <div class="article-content-area" v-if="!singleArticle">
              <div class="row row-header">
                  <div class="col-6">
                      Article Title
                  </div>
                  <div class="col-3">
                      Publication
                  </div>
                  <div class="col-3">
                      Published On
                  </div>
              </div>
              <div class="row row-spaced" v-for="post of posts" :key="post.id">
                  <div class="col-6">
                      <a :href="'/articles/' + post.titleSlug">{{ post.title }}</a>
                  </div>
                  <div class="col-3">
                      {{ post.publication }}
                  </div>
                  <div class="col-3">
                      {{ moment(post.publicationDate).format('MMM DD, YYYY') }}
                  </div>
              </div>

              <div class="pagination">
                  <a v-on:click="goBack()" class="pages goBack">&lt;</a>
                  <a v-on:click="goToPage(page)" :class="['pages', { 'current-page': currentPage === page }]" v-for="page of computedPaginationLinks" :key="page">{{ page }}</a>
                  <a v-on:click="goForward()" class="pages goForward">&gt;</a>
              </div>
          </div>
            <div class="article-content-area" v-if="singleArticle">
                <h3 class="fw-bold mb-3">
                    {{ singlePostContent.title }}
                </h3>
                <h5>
                  {{ singlePostContent.subtitle }}
                </h5>
                <h6>{{ moment(singlePostContent.publicationDate).format('MMM DD, YYYY') }}</h6>
                <p><a :href="singlePostContent.externalLink" target="_blank">{{ singlePostContent.publication }}</a></p>
                
                <!-- <p class="text-gray-200 mb-7">January 2021</p> -->
                <ContentLayout :content="singlePostContent.content"></ContentLayout>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-xxl-3">
            <div class="card">
              <div class="card-body">
                <MostRecentBooks></MostRecentBooks>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
// import MostInfluential from './MostInfluential.vue'
import MostRecentBooks from './MostRecentBooks.vue'
import ContentLayout from './feature/ContentLayout.vue'
import moment from 'moment'
var slugify = require('slugify')
export default {
  name: "ArticlePage",
  components: {
    Header,
    Footer,
    // MostInfluential,
    MostRecentBooks,
    ContentLayout
  },
  data() {
    return {
      slug: null,
      singleArticle: false,
      singlePostContent: null,
      posts: [],
      currentPage: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalPages: 1
    }
  },
  computed: {
      computedPaginationLinks: function() {
          var totalPages = this.totalPages
            
          var array = []
          var i = 1
          for(i; i <= totalPages; i++) {
              array.push(i)
          }

        return array
      }
  },
  mounted: async function() {
    this.slug = this.$route.params.slug
    this.currentPage = parseInt(this.$route.query.page)

    // if there's a slug - it's an article
    if(this.slug) {
      this.singleArticle = true
      await this.grabSinglePost(this.slug)
    } else {
      await this.grabPosts()
    }
    // if there's no slug, it's the category page except with every article ever
  },
  methods: {
    moment: function(date, format = null) {
        return moment(date, format)
    },
    grabSinglePost: function(slug) {
      console.log('slug', slug)
      this.$axios.get(`/posts?where[titleSlug][equals]=${slug}`).then(results => {
        console.log('single post', results)
        this.singlePostContent = results.data.docs[0]
      })
    },
    grabPosts: function() {
        this.$axios.get(`/posts?where[category][equals]=61b26869c1e21db759d75ec9&limit=20&sort=-publicationDate&page=${this.currentPage}`).then(results => {
          console.log('posts', results)
          this.posts = results.data.docs
          this.hasNextPage = results.data.hasNextPage
          this.hasPrevPage = results.data.hasPrevPage
          this.totalPages = results.data.totalPages
      })
    },
    goBack: async function() {
      if(this.hasPrevPage) {
          this.currentPage = this.currentPage - 1
          this.$router.push({ query: { page: this.currentPage }})
          await this.grabPosts()
      }
    },
    goForward: async function() {
      if(this.hasNextPage) {
          this.currentPage = this.currentPage + 1
          this.$router.push({ query: { page: this.currentPage }})
          await this.grabPosts()
      }
    },
    goToPage: async function(page) {
      this.currentPage = page

      this.$router.push({ query: { page: this.currentPage }})
      await this.grabPosts()
    },
    slugify: function(string) {
      return slugify(string).toLowerCase()
    }
  }
};
</script>
