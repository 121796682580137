<template>
  <div>
    <Header></Header>

    <section class="py-7">
      <div class="container">
        <div class="row gy-7">
          <div class="col-12 col-lg-8 col-xxl-9">
            <div class="article-content-area" v-if="messageSent">
              <h1><b>Messaged Phyllis</b></h1>
              <p>
                Message sent to Phyllis. Please wait 24-48 hours for response!
              </p>
            </div>
            <div class="article-content-area" v-if="!messageSent">
              <h1><b>Contact Phyllis</b></h1>

              <div class="form-append form-append-lg border">
                <input
                  class="form-control form-control-lg border-white"
                  type="text"
                  v-model="full_name"
                  placeholder="Full Name"
                />
              </div>
              <div class="form-append form-append-lg border">
                <input
                  class="form-control form-control-lg border-white"
                  type="text"
                  v-model="email"
                  placeholder="Email Address"
                />
              </div>
              <div class="form-append form-append-lg border">
                <input
                  class="form-control form-control-lg border-white"
                  type="text"
                  v-model="subject"
                  placeholder="Subject"
                />
              </div>
              <div class="form-append form-append-lg border">
                <textarea
                  class="form-control form-control-lg border-white"
                  type="text"
                  v-model="message"
                  placeholder="Message"
                ></textarea>
              </div>
              <a
                class="btn btn-warning btn-lg btn-pill px-xl-8"
                v-on:click="sendMessage()"
                >Send Message</a
              >
            </div>
          </div>
          <div class="col-12 col-lg-4 col-xxl-3">
            <div class="card">
              <div class="card-body">
                <MostRecentBooks></MostRecentBooks>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
// import MostInfluential from './MostInfluential.vue'
import MostRecentBooks from "./MostRecentBooks.vue";
import moment from "moment";

export default {
  name: "ContactUs",
  components: {
    Header,
    Footer,

    // MostInfluential,
    MostRecentBooks,
  },
  data() {
    return {
      full_name: null,
      email: null,
      subject: null,
      message: null,
      messageSent: false,
    };
  },
  computed: {},
  mounted: async function() {},
  methods: {
    moment: function(date, format = null) {
      return moment(date, format);
    },
    sendMessage: function() {
      this.$axios
        .post("https://phyllis-chesler.com/phyllis-contact/", {
          fullName: this.full_name,
          emailAddress: this.email,
          subject: this.subject,
          message: this.message,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      this.$axios
        .post("/contact-us-responses", {
          fullName: this.full_name,
          emailAddress: this.email,
          subject: this.subject,
          message: this.message,
        })
        .then(() => {
          this.messageSent = true;
        });
    },
  },
};
</script>
