<template>
    <span>
        <span v-html="computedContent"></span>       
    </span>
</template>

<script>
export default {
    props: ['content'],
    data() {
        return {

        }
    },
    computed: {
        computedContent: function() {
            var computedContent = ''
            for(const area of this.content) {
                // console.log('area', area)
                var children = area.children
                var type = area.type || 'p'
                // console.log(children, type)

                if(type === 'h1' || type === 'h2' || type === 'h3' || type === 'h4' || type === 'h5' || type === 'h6' || type === 'p') {
                    var string = '<' + type + '>'
                    for(const child of children) {
                        if(child.text) {
                            string += this.addTextNode(child)
                        }
                        if(child.type === 'link') {
                            var target = null
                            if(child.newTab) {
                                target = 'target="_blank"'
                            }

                            string += `<a href="${child.url}" ${target}>`
                                for(const achild of child.children) {
                                    string += this.addTextNode(achild)
                                }
                            string += '</a>'
                        }
                    }
                    string += '</' + type + '>'
                    computedContent += string
                }
                if(type === 'ul' || type === 'ol') {
                    string = '<' + type + '>'
                    for(const child of children) {
                        string += '<li>'
                            for(const achild of child.children) {
                                if(achild.text) {
                                    string += this.addTextNode(achild)
                                }
                                if(achild.type === 'link') {
                                    target = null
                                    if(achild.newTab) {
                                        target = 'target="_blank"'
                                    }

                                    string += `<a href="${achild.url}" ${target}>`
                                        for(const bchild of achild.children) {
                                            string += this.addTextNode(bchild)
                                        }
                                    string += '</a>'
                                }
                            }
                        string += '</li>'
                    }
                    string += '</' + type + '>'
                    computedContent += string
                }
                if(type === 'upload') {
                    computedContent += this.parseUpload(area)
                }
            }
            return computedContent
        }
    },
    methods: {
        addTextNode: function(child) {
            var text = child.text
            if(child.bold) {
                text = '<strong>' + child.text + '</strong>'
            }
            if(child.italic) {
                text = '<em>' + child.text + '</em>'
            }
            if(child.underline) {
                text = '<u>' + child.text + '</u>'
            }
            if(child.strikethrough) {
                text = '<s>' + child.text + '</s>'
            }
            if(child.code) {
                text = '<pre>' + child.text + '</pre>'
            }

            return text
        },
        parseUpload: function(area) {
            console.log('parseUpload', area, area.value.mimeType.search('image'))
            if(area.value.mimeType.search('image') > -1) {
                console.log('test')
                return `<img src="${area.value.url}" alt="${area.value.name}">`
            }
        }
    }
}
</script>
