<template>
    <div class="colored-box" :style="'background:' + content.hexColor ">
        <div class="row" v-for="area of content.row" :key="area.id">
            <PostContent 
                :key="area.id"
                v-if="area.blockType === 'postContent'"
                :content="area.content"
            ></PostContent>
            <FullWidthImage
                :key="area.id"
                v-if="area.blockType === 'fullWidthImage'"
                :content="area"
            ></FullWidthImage>
            <VideoEmbed
                :key="area.id"
                v-if="area.blockType === 'videoEmbed'"
                :content="area"
            ></VideoEmbed>
            <BlockQuote
                :key="area.id"
                v-if="area.blockType === 'blockQuote'"
                :content="area"
            ></BlockQuote>
            <AudioEmbed
                :key="area.id"
                v-if="area.blockType === 'audioEmbed'"
                :content="area"
            ></AudioEmbed>
            <SocialIcons
                :key="area.id"
                v-if="area.blockType === 'socialIcons'"
                :content="area"
            ></SocialIcons>
            <Columns
                :key="area.id"
                v-if="area.blockType === 'columns'"
                :content="area"
            ></Columns>
        </div>
    </div>
</template>

<script>
import PostContent from './PostContent.vue'
import FullWidthImage from './FullWidthImage.vue'
import Columns from './Columns.vue'
import VideoEmbed from './VideoEmbed.vue'
import BlockQuote from './BlockQuote.vue'
import SocialIcons from './SocialIcons.vue'
import AudioEmbed from './AudioEmbed.vue'
export default {
    props: ['content'],
    data() {
        return {

        }
    },
    components: {
        PostContent,
        FullWidthImage,
        Columns,
        VideoEmbed,
        BlockQuote,
        SocialIcons,
        AudioEmbed
    },
}
</script>

<style scoped>
.colored-box {
    padding: 10px;
    box-sizing: border-box;
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}
</style>
