import HomePage from './components/HomePage.vue'
import CategoryPage from './components/CategoryPage.vue'
import SearchPage from './components/SearchPage.vue'
import ArticlePage from './components/ArticlePage.vue'
import BooksPage from './components/BooksPage.vue'
import PagesPage from './components/PagesPage.vue'
import GalleryPage from './components/GalleryPage.vue'
import LectureMedia from './components/LectureMedia.vue'
import ContactUs from './components/ContactUs.vue'

export default [
  { path: '/', component: HomePage, name: 'home'},
  { path: '/category/:slug', component: CategoryPage, name: 'category'},
  
  { path: '/articles/:slug?', component: ArticlePage, name: 'article'},
  { path: '/search', component: SearchPage, name: 'search'},
  { path: '/gallery/:slug?', component: GalleryPage, name: 'gallery'},
  { path: '/pages/books/:slug?', component: BooksPage, name: 'books'},
  { path: '/pages/lectures-and-media/:slug?', component: LectureMedia, name: 'lecture'},
  { path: '/pages/contact-us', component: ContactUs, name: 'contactus'},
  { path: '/pages/:slug?', component: PagesPage, name: 'pages'},
]