<template>
<div>
    <div class="card-body-title">
        <div class="d-flex mb-2">
            <span class="text-gray-400 text-uppercase flex-grow-1"
            >Most recent Articles</span
            >
            <a class="pl-1 text-uppercase text-nowrap" href="/articles"
            >View more</a
            >
        </div>
    </div>
    <ul class="list-group list-group-flush list-group-flush-x">
        <li class="list-group-item" v-for="post of posts" :key="post.id">
            <p class="text-gray-800 lead fw-bold mb-1">
                {{ post.title }}
            </p>
            <a class="text-uppercase text-nowrap" :href="'/articles/' + post.titleSlug">
                {{ post.category.slug === 'lectures-and-media' ? 'Listen Now' : 'Read Now' }}
            </a>
        <!-- <div class="d-flex">
            <a href="#!" class="text-decoration-none text-facebook">
            <font-awesome-icon :icon="['fab', 'facebook-square']" />
            </a>
            <a
            href="#!"
            class="text-decoration-none text-twitter ml-2"
            >
            <font-awesome-icon :icon="['fab', 'twitter']" />
            </a>
        </div> -->
        </li>
    </ul>
</div>
</template>

<script>

export default {
    data() {
        return {
            posts: []
        }
    },
    mounted: async function() {
        await this.grabPosts()
    },
    methods: {
        grabPosts: function() {
            this.$axios.get(`/posts?limit=6&sort=-publicationDate&page=${this.currentPage}`).then(results => {
                console.log('posts', results)
                this.posts = results.data.docs
            })
        },
    }
}
</script>
