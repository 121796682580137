<template>
    <span>
        <audio controls>        
            <source :src="content.url">
        </audio>
    </span>
</template>

<script>
export default {
    props: ['content'],
    data() {
        return {

        }
    },
}
</script>
