<template>
  <div>
    <Header></Header>

    <section class="py-7">
      <div class="container">
        <div class="row gy-7">
          <div class="col-12 col-lg-8 col-xxl-9">
            <div class="article-content-area" v-if="page">
                
                <h3 class="fw-bold mb-3">
                    {{ page.title }}
                </h3>
                <!-- <p class="text-gray-200 mb-7">January 2021</p> -->
                <ContentLayout :content="page.content"></ContentLayout>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-xxl-3">
            <div class="card">
              <div class="card-body">
                <MostRecentBooks></MostRecentBooks>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
// import MostInfluential from './MostInfluential.vue'
import MostRecentBooks from './MostRecentBooks.vue'
import ContentLayout from './feature/ContentLayout.vue'
import moment from 'moment'
export default {
  name: "ArticlePage",
  components: {
    Header,
    Footer,
    // MostInfluential,
    MostRecentBooks,
    ContentLayout
  },
  data() {
    return {
      slug: null,
      page: null
    }
  },
  computed: {
    
  },
  mounted: async function() {
    this.slug = this.$route.params.slug
    console.log('this.slug', this.slug)

    await this.grabPage()
    // // if there's a slug - it's an article
    // if(this.slug) {
    //   this.singleArticle = true
    //   await this.grabSinglePost(this.slug)
    // } else {
    //   await this.grabPosts()
    // }
    // // if there's no slug, it's the category page except with every article ever
  },
  methods: {
    moment: function(date, format = null) {
        return moment(date, format)
    },
    grabPage: function() {
        this.$axios.get(`/pages?limit=1&where[slug][equals]=${this.slug}`).then(results => {
          console.log('page', results)
          this.page = results.data.docs[0]
      })
    },
  }
};
</script>
