<template>
    <span>
        <img width="100%" :src="content.image.url" :alt="content.image.name">
        <p class="image-description">{{ content.description }}</p>
    </span>
</template>

<script>
export default {
    props: ['content'],
    data() {
        return {

        }
    },
}
</script>
