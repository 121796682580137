<template>
    <span>
        <iframe :src="content.url" :alt="content.description" frameborder="0"></iframe>
    </span>
</template>

<script>
export default {
    props: ['content'],
    data() {
        return {

        }
    },
}
</script>
