<template>
  <div>
    <header class="bg-gradient-primary pt-xl-7">
      <div class="bg-gradient-primary-dark">
        <div class="row no-gutters">
          <div class="col-12 order-2 order-xl-1">
            <div class="container">
              <div class="row">
                <div class="col-12 col-lg-4 text-center py-5">
                  <h3 class="text-white fw-bold mb-4">
                    Welcome to my website
                  </h3>
                  <p class="h5 text-white mb-4">
                    Where I’ve archived interviews and what I’ve written in the
                    last quarter-century.
                  </p>
                  <div class="form-append form-append-lg">
                    <span class="form-append-icon text-gray-800" v-on:click="searchArticles()">
                      <font-awesome-icon icon="search" />
                    </span>
                    <input
                      class="form-control form-control-lg border-white"
                      type="text"
                      v-model="searchText"
                      v-on:keyup.enter="searchArticles()"
                      placeholder="Search Articles"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-4 text-center">
                  <img src="https://cdn.blacktiecollab.com/phyllis-chesler.com/images/315.jpg" class="h-100 w-100 of-cover" />
                </div>
                <div class="col-12 col-lg-4 text-center py-5">
                  <p class="h5 text-white mb-4">
                    If my work finds favor in your eyes, please consider making
                    a donation.
                  </p>
                  <div class="mb-4">
                  
                  </div>
                  <a href="https://www.paypal.com/donate/?hosted_button_id=E5NMQQGVGLA6G" target="_blank" class="btn btn-warning btn-lg btn-pill px-xl-8">
                    Donate
                  </a>

                  <input
                      class="form-control form-control-lg border-white"
                      type="text"
                      v-model="newsletterEmail"
                      style="margin-top: 20px;"
                      placeholder="Sign up for newsletter! Enter your email."
                  />

                  <button class="btn btn-success btn-sm" style="margin-top: 10px;" v-on:click="submitNewsletterSignup">Sign up!</button>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 order-1 order-xl-2">
            <b-navbar toggleable="xl" type="custom" class="bg-gradient-primary">
              <div class="container">
                <b-navbar-brand href="/">
                  <span class="logo">
                    <span class="logo-title">PHYLLIS CHESLER</span>
                    <span class="logo-subtitle">ORGANIZATION</span>
                  </span>
                </b-navbar-brand>

                <b-navbar-toggle target="nav-collapse">
                  <template>
                    <font-awesome-icon icon="bars" class="text-white" />
                  </template>
                </b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                  <b-navbar-nav class="ml-xl-auto">
                    <b-nav-item to="/" exact>Home</b-nav-item>
                    <b-nav-item v-on:click="showCategories = !showCategories">Categories</b-nav-item>
                    <b-nav-item href="/pages/lectures-and-media">Media & Lectures</b-nav-item>
                    <b-nav-item href="/articles">Articles</b-nav-item>
                    <b-nav-item href="/pages/books">Books</b-nav-item>
                    <b-nav-item href="/pages/about-phyllis">Bio</b-nav-item>
                    <b-nav-item href="/gallery">Photos</b-nav-item>
                    <b-nav-item href="/pages/contact-us">Contact</b-nav-item>
                  </b-navbar-nav>
                </b-collapse>
              </div>
            </b-navbar>
          </div> 
        </div>
      </div>
    </header>
    <div class="navigation" v-if="showCategories">
      <a class="nav-link" :href="'/category/' + category.slug" v-for="category of categories" :key="category.id">{{ category.name }}</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      categories: [],
      showCategories: false,
      searchText: null,
      newsletterEmail: null
    }
  },
  mounted: function() {
    // https://admin.phyllis-chesler.com/api/tags?limit=100
    this.$axios.get('/tags?limit=40&sort=name').then(results => {
      console.log('results', results.data)
      this.categories = results.data.docs
    })
  },
  methods: {
    searchArticles: function() {
      this.$router.push({ path: '/search', query: { q: this.searchText }})
    },
    submitNewsletterSignup: function() {
      // where is the email?
      axios.post('https://phyllis-chesler.com/mailchimp-phyllis/', {
        email: this.newsletterEmail
      }).then(results => {
        console.log('results', results)
        alert('You have been signed up for the newsletter!')
      }).catch(err => {
        console.log('err', err)
        alert(`Error: ${err.message}`)
      })

      // axios.post('https://admin.phyllis-chesler.com/api/contact-us-responses/newsletter-signup', {
      //   email: this.newsletterEmail
      // }).then(results => {
      //   console.log('results', results)
      // })
    }
  }
}
</script>
