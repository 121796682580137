<template>
  <div>
    <Header></Header>

    <section class="py-7">
      <div class="container">
        <div class="row gy-7">
          <div class="col-12 col-lg-8 col-xxl-9">
            <div class="article-content-area" v-if="!singleGallery">
              <div class="gallery-box" v-for="gallery of galleries" :key="gallery.id">
                  <div class="gallery-box-image">
                      <img :src="gallery.photos[0].image.url" v-if="gallery.photos[0].image" width="100%" alt="">
                      <img :src="gallery.photos[0].orImageUrl" v-if="gallery.photos[0].orImageUrl" width="100%" alt="">
                  </div>
                  <div class="gallery-box-text">
                      {{ gallery.name }}
                  </div>
                  <div class="gallery-box-link">
                      <a :href="'/gallery/' + gallery.titleSlug" class="button">View Gallery</a>
                  </div>
              </div>

              <div class="pagination">
                  <a v-on:click="goBack()" class="pages goBack">&lt;</a>
                  <a v-on:click="goToPage(page)" :class="['pages', { 'current-page': currentPage === page }]" v-for="page of computedPaginationLinks" :key="page">{{ page }}</a>
                  <a v-on:click="goForward()" class="pages goForward">&gt;</a>
              </div>
          </div>
            <div class="article-content-area" v-if="singleGallery">
                <a href="/gallery" class="button">&lt; Back to Galleries</a>
                <br><br>
                <h3 class="fw-bold mb-3">
                    {{ singlePostContent.name }}
                </h3>
                <p class="text-gray-200 mb-7">{{ moment(singlePostContent.publicationDate).format('MMM DD, YYYY') }}</p>
                <span v-for="photo of singlePostContent.photos" :key="photo.id">
                  <img :src="photo.image.url" v-if="photo.image" width="100%" alt="">
                  <img :src="photo.orImageUrl" class="gallery-image" v-if="photo.orImageUrl" alt="">  
                  <p>{{ photo.imageDescription }}</p>
                </span>
                
            </div>
          </div>
          <div class="col-12 col-lg-4 col-xxl-3">
            <div class="card">
              <div class="card-body">
                <MostRecentBooks></MostRecentBooks>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
// import MostInfluential from './MostInfluential.vue'
import MostRecentBooks from './MostRecentBooks.vue'
import moment from 'moment'

export default {
  name: "ArticlePage",
  components: {
    Header,
    Footer,
    // MostInfluential,
    MostRecentBooks
  },
  data() {
    return {
      slug: null,
      singleGallery: false,
      singlePostContent: null,
      galleries: [],
      currentPage: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalPages: 1
    }
  },
  computed: {
      computedPaginationLinks: function() {
          var totalPages = this.totalPages
            
          var array = []
          var i = 1
          for(i; i <= totalPages; i++) {
              array.push(i)
          }

        return array
      }
  },
  mounted: async function() {
    this.slug = this.$route.params.slug
    this.currentPage = parseInt(this.$route.query.page)

    // if there's a slug - it's an article
    if(this.slug) {
      this.singleGallery = true
      await this.grabSinglePost(this.slug)
    } else {
      await this.grabPosts()
    }
    // if there's no slug, it's the category page except with every article ever
  },
  methods: {
    moment: function(date, format = null) {
        return moment(date, format)
    },
    grabSinglePost: function(slug) {
      this.$axios.get(`/galleries?where[titleSlug][like]=${slug}`).then(results => {
        console.log("single post", results)
        this.singlePostContent = results.data.docs[0]
      })
    },
    grabPosts: function() {
        this.$axios.get(`/galleries?limit=20&sort=-publicationDate&page=${this.currentPage}`).then(results => {
          console.log('gallery', results)
          this.galleries = results.data.docs
          this.hasNextPage = results.data.hasNextPage
          this.hasPrevPage = results.data.hasPrevPage
          this.totalPages = results.data.totalPages
      })
    },
    goBack: async function() {
      if(this.hasPrevPage) {
          this.currentPage = this.currentPage - 1
          this.$router.push({ query: { page: this.currentPage }})
          await this.grabPosts()
      }
    },
    goForward: async function() {
      if(this.hasNextPage) {
          this.currentPage = this.currentPage + 1
          this.$router.push({ query: { page: this.currentPage }})
          await this.grabPosts()
      }
    },
    goToPage: async function(page) {
      this.currentPage = page

      this.$router.push({ query: { page: this.currentPage }})
      await this.grabPosts()
    },
    slugify: function(string) {
      return string
    }
  }
};
</script>
