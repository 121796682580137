<template>
    <div class="row">
        <div :class="'col-md-' + (column.blockName || 6)" v-for="column of content.column" :key="column.id">
            <PostContent 
                :key="column.id"
                v-if="column.blockType === 'postContent'"
                :content="column.content"
            ></PostContent>
            <FullWidthImage
                :key="column.id"
                v-if="column.blockType === 'fullWidthImage'"
                :content="column"
            ></FullWidthImage>
            <VideoEmbed
                :key="column.id"
                v-if="column.blockType === 'videoEmbed'"
                :content="column"
            ></VideoEmbed>
            <BlockQuote
                :key="column.id"
                v-if="column.blockType === 'blockQuote'"
                :content="column"
            ></BlockQuote>
            <AudioEmbed
                :key="column.id"
                v-if="column.blockType === 'audioEmbed'"
                :content="column"
            ></AudioEmbed>
            <SocialIcons
                :key="column.id"
                v-if="column.blockType === 'socialIcons'"
                :content="column"
            ></SocialIcons>
        </div>
        
    </div>
</template>

<script>
import PostContent from './PostContent.vue'
import FullWidthImage from './FullWidthImage.vue'
import VideoEmbed from './VideoEmbed.vue'
import BlockQuote from './BlockQuote.vue'
import SocialIcons from './SocialIcons.vue'
import AudioEmbed from './AudioEmbed.vue'
export default {
    props: ['content'],
    data() {
        return {

        }
    },
    components: {
        PostContent,
        FullWidthImage,
        VideoEmbed,
        BlockQuote,
        SocialIcons,
        AudioEmbed
    },
    
}
</script>
