<template>
<span>
    <template v-for="social of content.social">
        <a :key="social.id" :href="social.socialUrl" class="social-icon" v-if="social.selectSocial === 'facebook'"><i class="fa-brands fa-facebook"></i></a>
        <a :key="social.id" :href="social.socialUrl" class="social-icon" v-if="social.selectSocial === 'twitter'"><i class="fa-brands fa-twitter"></i></a>
        <a :key="social.id" :href="social.socialUrl" class="social-icon" v-if="social.selectSocial === 'instagram'"><i class="fa-brands fa-instagram"></i></a>
        <a :key="social.id" :href="social.socialUrl" class="social-icon" v-if="social.selectSocial === 'googleApp'"><i class="fa-brands fa-google-play"></i></a>
        <a :key="social.id" :href="social.socialUrl" class="social-icon" v-if="social.selectSocial === 'iosApp'"><i class="fa-brands fa-app-store-ios"></i></a>
        <a :key="social.id" :href="social.socialUrl" class="social-icon" v-if="social.selectSocial === 'youtube'"><i class="fa-brands fa-youtube"></i></a>
    </template>
</span>
</template>

<script>

export default {
    props: ['content'],
    data() {
        return {

        }
    },
}
</script>
