import Vue from "vue";
import VueRouter from "vue-router";
import { BootstrapVue } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faBars,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faFacebookSquare,
  faLinkedin,
  faTwitter,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Flickity from "vue-flickity";
import OtpInput from "@bachdgvn/vue-otp-input";
import axios from "axios";
import App from './App.vue'
import Routes from './routes'

import "./assets/scss/styles.scss";

Vue.use(VueRouter);

Vue.use(BootstrapVue);

library.add(
  faSearch,
  faBars,
  faFacebook,
  faFacebookSquare,
  faLinkedin,
  faTwitter,
  faYoutube,
  faInstagram,
  faQuoteRight
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("flickity", Flickity);
Vue.component("v-otp-input", OtpInput);

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
Vue.prototype.$axios = axios;

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: Routes,
});

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
