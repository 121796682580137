<template>
    <div class="quote">
        <div class="quote-image">&#8220;&#8221;</div>
        <div class="quote-content">
            {{ content.quote }}
        </div>
        <div class="quote-author">
            - {{ content.author }}
        </div>
    </div>
</template>

<script>
export default {
    props: ['content'],
    data() {
        return {

        }
    },
}
</script>

<style scoped>
.quote {
    padding: 10px;
    background: #eee;
    line-height: 1.5;
    position: relative;
}

.quote-image {
    position: absolute;
    right: 10px;
    top: -30px;
    font-size: 90px;
    opacity: 0.3;
}

.quote-content {
    
}

.quote-author {
    font-size: 13px;
    color: #888;
}
</style>