<template>
  <div>
    <Header></Header>

    <section class="py-7">
      <div class="container">
        <div class="row gy-7">
          <div class="col-12 col-lg-8 col-xxl-9">
              <div class="article-content-area">
                    <div class="row row-header">
                        <div class="col-6">
                            Article Title
                        </div>
                        <div class="col-3">
                            Publication
                        </div>
                        <div class="col-3">
                            Published On
                        </div>
                    </div>
                    <div class="row row-spaced" v-for="post of posts" :key="post.id">
                        <div class="col-6">
                            <a :href="'/articles/' + post.titleSlug">{{ post.title }}</a>
                        </div>
                        <div class="col-3">
                            {{ post.publication }}
                        </div>
                        <div class="col-3">
                            {{ moment(post.publicationDate).format('MMM DD, YYYY') }}
                        </div>
                    </div>

                    <div class="pagination">
                        <a v-on:click="goBack()" class="pages goBack">&lt;</a>
                        <a v-on:click="goToPage(page)" :class="['pages', { 'current-page': currentPage === page }]" v-for="page of computedPaginationLinks" :key="page">{{ page }}</a>
                        <a v-on:click="goForward()" class="pages goForward">&gt;</a>
                    </div>
                </div>
          </div>
          <div class="col-12 col-lg-4 col-xxl-3">
            <div class="card">
              <div class="card-body">
                
                <MostRecentBooks></MostRecentBooks>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
// import MostInfluential from './MostInfluential.vue'
import MostRecentBooks from './MostRecentBooks.vue'
import moment from 'moment'
var slugify = require('slugify')
export default {
  name: "ArticlePage",
  components: {
    Header,
    Footer,
    // MostInfluential,
    MostRecentBooks
  },
  data() {
      return {
        tag: null,
        posts: [],
        currentPage: 1,
        hasNextPage: false,
        hasPrevPage: false,
        totalPages: 1
      }
  },
  computed: {
      computedPaginationLinks: function() {
          var totalPages = this.totalPages
            
          var array = []
          var i = 1
          for(i; i <= totalPages; i++) {
              array.push(i)
          }

        return array
      }
  },
  mounted: async function() {
    var slug = this.$route.params.slug

    this.currentPage = parseInt(this.$route.query.page)
    //https://phyllis-chesler.com/articles/womens-studies-is-betraying-women-under-sharia-law
    await this.grabTag(slug)
  },
  methods: {
      moment: function(date, format = null) {
          return moment(date, format)
      },
      grabTag: function(slug) {
        this.$axios.get('/tags?where[slug][equals]=' + slug).then(async results => {
            console.log('tags', results)
            this.tag = results.data.docs[0]

            await this.grabPosts()
        })
      },
      grabPosts: function() {
          this.$axios.get(`/posts?where[tags][in]=${this.tag.id}&limit=20&sort=-publicationDate&page=${this.currentPage}`).then(results => {
            console.log('posts', results)
            this.posts = results.data.docs
            this.hasNextPage = results.data.hasNextPage
            this.hasPrevPage = results.data.hasPrevPage
            this.totalPages = results.data.totalPages
        })
      },
      goBack: async function() {
          if(this.hasPrevPage) {
              this.currentPage = this.currentPage - 1
              this.$router.push({ query: { page: this.currentPage }})
              await this.grabPosts()
          }
      },
      goForward: async function() {
          if(this.hasNextPage) {
              this.currentPage = this.currentPage + 1
              this.$router.push({ query: { page: this.currentPage }})
              await this.grabPosts()
          }
      },
      goToPage: async function(page) {
          this.currentPage = page
    
          this.$router.push({ query: { page: this.currentPage }})
          await this.grabPosts()
      },
      slugify: function(string) {
          return slugify(string).toLowerCase()
      }
  }
};
</script>
