<template>
  <div>
    <Header></Header>

    <section class="py-7">
      <div class="container">
        <div class="row gy-7">
          <div class="col-12 col-lg-8 col-xxl-9">
            <div class="article-content-area" v-if="!singleArticle">
              <div class="book" v-for="post of posts" :key="post.id">
                <h3 class="fw-bold mb-3">
                    {{ post.title }}
                </h3>
                <div class="row">
                  <div class="col-md-4" v-if="post.shortImage">
                    <img :src="post.shortImage.url" width="100%" alt="">
                    <br>
                    <a :href="post.purchaseUrl" v-if="post.purchaseUrl" class="button">Purchase</a>
                    <br>
                    <a :href="'/pages/books/'+post.slug" class="button">View Full Description</a>
                  </div>
                  <div class="col-md-8">
                    <span v-for="content of post.shortContent" :key="content.id">
                      <p>
                        <span v-for="p of content.children" :key="p.text">
                            <span :class="[{ italic: p.italic === 'true' }, { bold: p.bold === 'true' }, { underline: p.underline === 'true' } ]" v-if="p.text">
                                {{ p.text }}
                            </span>
                            <span v-if="p.type === 'link'">
                                <a :href="p.url">
                                    <span :class="[{ italic: p.italic === 'true' }, { bold: p.bold === 'true' }, { underline: p.underline === 'true' } ]" v-for="p of p.children" :key="p.text">
                                        {{ p.text }}
                                    </span>
                                </a>
                            </span>
                        </span>
                      </p>
                    </span>
                  </div>
                </div>
              </div>

              <div class="pagination">
                  <a v-on:click="goBack()" class="pages goBack">&lt;</a>
                  <a v-on:click="goToPage(page)" :class="['pages', { 'current-page': currentPage === page }]" v-for="page of computedPaginationLinks" :key="page">{{ page }}</a>
                  <a v-on:click="goForward()" class="pages goForward">&gt;</a>
              </div>
          </div>
            <div class="article-content-area" v-if="singleArticle">
              <h3 class="fw-bold mb-3">
                  {{ singlePostContent.title }}
              </h3>
              <ContentLayout :content="singlePostContent.content"></ContentLayout>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-xxl-3">
            <div class="card">
              <div class="card-body">
                <MostRecentBooks></MostRecentBooks>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
// import MostInfluential from './MostInfluential.vue'
import MostRecentBooks from './MostRecentBooks.vue'
import ContentLayout from './feature/ContentLayout.vue'
import moment from 'moment'
var slugify = require('slugify')
export default {
  name: "ArticlePage",
  components: {
    Header,
    Footer,
    // MostInfluential,
    MostRecentBooks,
    ContentLayout
  },
  data() {
    return {
      slug: null,
      singleArticle: false,
      singlePostContent: null,
      posts: [],
      currentPage: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalPages: 1
    }
  },
  computed: {
      computedPaginationLinks: function() {
          var totalPages = this.totalPages
            
          var array = []
          var i = 1
          for(i; i <= totalPages; i++) {
              array.push(i)
          }

        return array
      }
  },
  mounted: async function() {
    this.slug = this.$route.params.slug
  
    // if there's a slug - it's an article
    if(this.slug) {
      this.singleArticle = true
      await this.grabSinglePost(this.slug)
    } else {
      await this.grabPosts()
    }
    // if there's no slug, it's the category page except with every article ever
  },
  methods: {
    moment: function(date, format = null) {
        return moment(date, format)
    },
    grabSinglePost: function(slug) {
      this.$axios.get(`/pages?where[slug][like]=${slug}`).then(results => {
        console.log("single post", results)
        this.singlePostContent = results.data.docs[0]
      })
    },
    grabPosts: function() {
        this.$axios.get(`/pages?limit=20&where[category][equals]=6202ea13cb215899d8eeceaf&sort=-publicationDate&page=${this.currentPage}`).then(results => {
          this.posts = results.data.docs
          this.hasNextPage = results.data.hasNextPage
          this.hasPrevPage = results.data.hasPrevPage
          this.totalPages = results.data.totalPages
      })
    },
    goBack: async function() {
      if(this.hasPrevPage) {
          this.currentPage = this.currentPage - 1
          this.$router.push({ query: { page: this.currentPage }})
          await this.grabPosts()
      }
    },
    goForward: async function() {
      if(this.hasNextPage) {
          this.currentPage = this.currentPage + 1
          this.$router.push({ query: { page: this.currentPage }})
          await this.grabPosts()
      }
    },
    goToPage: async function(page) {
      this.currentPage = page

      this.$router.push({ query: { page: this.currentPage }})
      await this.grabPosts()
    },
    slugify: function(string) {
      return slugify(string).toLowerCase()
    }
  }
};
</script>
