<template>
  <div>
    <Header></Header>

    <section class="py-7">
      <div class="container">
        <div class="books-title">
          Books By Phyllis
        </div>
        <div class="books">
          <div class="book" v-for="book of books" :key="book.id">
            <a :href="'/pages/books/' + book.slug"><img :src="book.shortImage.url" width="100%" alt=""></a>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row gy-7">
          <div class="col-12 col-lg-8 col-xxl-9">
              <div class="row">
                <div class="col-12">
                  <div class="article-content-area" v-if="!singleArticle">
                    <div class="book" v-for="post of posts" :key="post.id">
                      <h3 class="fw-bold mb-3">
                          {{ post.title }}
                      </h3>
                      <div class="row">
                        <div class="col-md-4" v-if="post.shortImage">
                          <img :src="post.shortImage.url" width="100%" alt="">
                          <br>
                          <a :href="post.purchaseUrl" v-if="post.purchaseUrl" class="button">Purchase</a>
                          <br>
                          <a :href="'/pages/books/'+post.slug" class="button">View Full Description</a>
                        </div>
                        <div class="col-md-8">
                          <span v-for="content of post.shortContent" :key="content.id">
                            <p>
                              <span v-for="p of content.children" :key="p.text">
                                  <span :class="[{ italic: p.italic === 'true' }, { bold: p.bold === 'true' }, { underline: p.underline === 'true' } ]" v-if="p.text">
                                      {{ p.text }}
                                  </span>
                                  <span v-if="p.type === 'link'">
                                      <a :href="p.url">
                                          <span :class="[{ italic: p.italic === 'true' }, { bold: p.bold === 'true' }, { underline: p.underline === 'true' } ]" v-for="p of p.children" :key="p.text">
                                              {{ p.text }}
                                          </span>
                                      </a>
                                  </span>
                              </span>
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-xxl-3">
            <div class="card">
              <div class="card-body">
                <MostRecentBooks></MostRecentBooks>
                <br/>
                <h5 class="fw-bold mb-3">
                  Association for Women in Psychology, Spring, 2021.
                </h5>
                <iframe src="https://cdn.blacktiecollab.com/videoplayer/?play=https://cdn.blacktiecollab.com/phyllis-chesler.com/AWP_Phyllis Chesler Invited Interview_recorded February 19, 2021.mp4&amp;poster=https://cdn.blacktiecollab.com/phyllis-chesler.com/images/CleanShot 2021-11-17 at 12.58.02@2x.png" width="100%" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen=""></iframe>
                <br/>
                <h5 class="fw-bold mb-3">
                  Discussion of An American Bride in Kabul, Fall, 2013.
                </h5>
                <iframe width="100%" height="180" src="https://www.youtube.com/embed/Hvpm1lzhD1k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h5>
                  Requiem Lecture, April 19, 2022
                </h5>
                <iframe width="100%" height="180" src="https://cdn.blacktiecollab.com/videoplayer/?play=https://cdn.blacktiecollab.com/phyllis-chesler.com/GMT20220419-233250_Recording_640x360.mp4&poster=https://cdn.blacktiecollab.com/phyllis-chesler.com/CleanShot 2022-04-20 at 22.51.43@2x.png" frameborder="0"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
// import MostInfluential from './MostInfluential.vue'
import MostRecentBooks from './MostRecentBooks.vue'
import moment from 'moment'
var slugify = require('slugify')
import qs from 'qs';
export default {
  name: "ArticlePage",
  components: {
    Header,
    Footer,
    // MostInfluential,
    MostRecentBooks
  },
  data() {
    return {
      slug: null,
      singleArticle: false,
      singlePostContent: null,
      posts: [],
      currentPage: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalPages: 1,
      books: [],
    }
  },
  computed: {
      computedPaginationLinks: function() {
          var totalPages = this.totalPages
            
          var array = []
          var i = 1
          for(i; i <= totalPages; i++) {
              array.push(i)
          }

        return array
      }
  },
  mounted: async function() {
    this.slug = this.$route.params.slug
  
    // if there's a slug - it's an article
    if(this.slug) {
      this.singleArticle = true
      await this.grabSinglePost(this.slug)
    } else {
      await this.grabPosts()
    }

    await this.grabBooks()
    // if there's no slug, it's the category page except with every article ever
  },
  methods: {
    moment: function(date, format = null) {
        return moment(date, format)
    },
    grabSinglePost: function(slug) {
      this.$axios.get(`/pages?where[slug][like]=${slug}`).then(results => {
        console.log("single post", results)
        this.singlePostContent = results.data.docs[0]
      })
    },
    grabPosts: function() {
      var query = { 
        displayOnHomepage: {
          equals: 1
        },
        
        // id: {
        //   // in: ['6206cb4837a660701d2852ef', '62032ef2afbb46a261bd2497', '6206cefa37a660701d285463', '6206d50037a660701d285843', '6206c9eb37a660701d2851c7', '6202e87dcb215899d8eecdb6']
        // }
      }

      const stringifiedQuery = qs.stringify({
        where: query,
        sort: 'order' // ensure that `qs` adds the `where` property, too!
      }, { addQueryPrefix: true })
    
      console.log('stringifiedQuery', stringifiedQuery)

        this.$axios.get(`/pages${stringifiedQuery}`).then(results => {
          this.posts = results.data.docs
          this.hasNextPage = results.data.hasNextPage
          this.hasPrevPage = results.data.hasPrevPage
          this.totalPages = results.data.totalPages
      })
    },
    grabBooks: function() {
        this.$axios.get(`/pages?limit=8&where[category][equals]=6202ea13cb215899d8eeceaf&sort=-publicationDate&page=${this.currentPage}`).then(results => {
          this.books = results.data.docs
          // this.hasNextPage = results.data.hasNextPage
          // this.hasPrevPage = results.data.hasPrevPage
          // this.totalPages = results.data.totalPages
      })
    },
    goBack: async function() {
      if(this.hasPrevPage) {
          this.currentPage = this.currentPage - 1
          this.$router.push({ query: { page: this.currentPage }})
          await this.grabPosts()
      }
    },
    goForward: async function() {
      if(this.hasNextPage) {
          this.currentPage = this.currentPage + 1
          this.$router.push({ query: { page: this.currentPage }})
          await this.grabPosts()
      }
    },
    goToPage: async function(page) {
      this.currentPage = page

      this.$router.push({ query: { page: this.currentPage }})
      await this.grabPosts()
    },
    slugify: function(string) {
      return slugify(string).toLowerCase()
    }
  }
};
</script>
